<script setup>
import { ref } from 'vue'
import { VuePDF, usePDF } from '@tato30/vue-pdf'

const props = defineProps({
  url: String,
  token: String,
})

const url = props.url
const withCredentials = false
const src = {
  url,
  withCredentials,
  httpHeaders: {
    Authorization: 'Bearer ' + props.token
  },
  verbosity: 0
}


const { pdf, pages } = usePDF(src)

const scale = ref(1.0)

const pagesContainer = ref(null)
const pagesPDF = ref(null)
const currentPage = ref(1)



const baixarArquivo = () => {
  const params = { token: props.token };
  const paramsUrl = '?' + new URLSearchParams(params).toString();
  window.open(src.url + paramsUrl, '_blank');
};

const scrollToLink = (id_page) => {
  const linkElement = document.getElementById('page-' + id_page)

  if (linkElement) {
    linkElement.scrollIntoView()
  }
}

const pagePositions = {}

const checkpage = () => {
  if (!pagesPDF.value) return

  for (let pageNumber = 1; pageNumber <= pages.value; pageNumber++) {
    const pageElement = document.getElementById(`page-${pageNumber}`)
    if (pageNumber == 1) {
      pagePositions[1] = 0
    } else {
      pagePositions[pageNumber] = pageElement.offsetTop - 200
    }
  }
}

const handleScroll = () => {
  checkpage()
  const scrollTop = pagesContainer.value.scrollTop
  let pag = 1

  for (let pageNumber = 1; pageNumber <= pages.value; pageNumber++) {
    if (scrollTop >= pagePositions[pageNumber]) {
      pag = pageNumber
    }
  }

  currentPage.value = pag;
}

</script>

<template>
  <div class="flex bg-transparent flex-column gap-2 absolute w-full left-0" style="z-index: 500; bottom: 40px">
    <div class="flex justify-content-center">
      <div class="flex flex-row gap-2" style="
          padding: 0.2em 1rem;
          background: white;
          border-radius: 1em;
          border: 1px solid rgb(199 199 199 / 76%);
        ">
        <div class="flex align-items-center justify-content-end" style="">
          <Button icon="pi pi-search-minus" text @click="scale = scale > 0.5 ? scale - 0.25 : scale" />
          <span>{{ scale * 100 }}%</span>
          <Button icon="pi pi-search-plus" text @click="scale = scale < 2 ? scale + 0.25 : scale" />
        </div>
        <div class="align-items-center justify-content-start hidden md:flex">
          <Button icon="pi pi-arrow-up" text
            @click="scrollToLink((currentPage = currentPage > 1 ? currentPage - 1 : currentPage))" />
          <span>{{ currentPage }} / {{ pages }}</span>
          <Button icon="pi pi-arrow-down" text @click="
            scrollToLink((currentPage = currentPage < pages ? currentPage + 1 : currentPage))
            " />
        </div>
        <div class="flex align-items-center justify-content-end">
          <Button icon="pi pi-download" text @click="baixarArquivo(solicitacao_id)" />
        </div>
      </div>
    </div>
  </div>

  <div style="height: 65vh; contain: content; overflow-y: auto" ref="pagesContainer" @scroll="{ handleScroll(); }">
    <div style="
        padding-top: 2rem;
        padding-bottom: 2rem;
        background: #fff;
        overflow: auto;
        width: auto;
        min-width: fit-content;
        padding-left: 2rem;
        padding-right: 2rem;
      " ref="pagesPDF">
      <div v-for="pageNumber in pages" :key="pageNumber" :id="`page-${pageNumber}`" class="page"
        style="display: flex; justify-content: center; margin-bottom: 0.5rem">
        <VuePDF :pdf="pdf" :scale="scale" :page="pageNumber" style="border: 1px solid #c7c7c7" />
      </div>
    </div>
  </div>
</template>

<style>
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.p-dialog .p-dialog-header {
  border-top-right-radius: 1.5rem !important;
  border-top-left-radius: 1.5rem !important;
}
.p-dialog .p-dialog-footer{
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.p-dialog {
  border-radius: 1.5rem;
}
</style>
<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
    superiorEsquerda: String,
    superiorDireita: String,
    titulo: String,
    subtitulo: String,
    rodape: String,
    clicavel: Boolean,
})

const dadosRecebidos = ref({});

onMounted(() => {
    dadosRecebidos.value = props.dados;
})
</script>

<template>
    <div
        class="w-full border-round border-1 surface-border bg-white py-3 px-3 mb-2" :class=" { 'cursor-pointer hover:border-300 hover:bg-gray-50': props.clicavel }">
        <div class="flex justify-content-between align-items-center">
            <span class="text-700 font-normal text-sm" v-if="props.superiorEsquerda">{{ props.superiorEsquerda }}</span>
            <slot name="superiorEsquerda">
            </slot>
            <span class="text-700 font-normal text-sm" v-if="props.superiorDireita">{{ props.superiorDireita }}</span>
            <slot name="superiorDireita">
            </slot>
        </div>
        
        <p class="text-900 font-semibold mb-0" v-if="props.titulo">{{ props.titulo }}</p>
        <slot name="titulo">
        </slot>
        <slot name="subtitulo">
        </slot>

        <p class="text-700 font-normal text-sm mt-2" v-if="props.subtitulo">{{ props.subtitulo }}</p>

        <slot name="rodape">
        </slot>
    </div>
</template>
import { ref, reactive, computed } from 'vue';
import { defineStore } from 'pinia';
import md5 from 'md5';
import { jwtDecode } from 'jwt-decode';


export const useAuthStore = defineStore('authstore', () =>{
    const usuario = reactive({
        id: null,
        nome: '',
        avatar: '',
        pessoa_fisica_id: null,
        nome_pessoa: '',
        email: '',
    });
    const token = ref(null);
    const refreshToken = ref(null);
    const isAuthenticated = computed(() => !!usuario.nome && !!token.value);
    const setUsuario = (newUser) => {
        usuario.id = newUser.id;
        usuario.nome = newUser.nome_usuario;
        usuario.email = newUser.email;
        const email_hash = md5((typeof usuario.email === 'string') ? usuario.email.trim().toLowerCase() : '');
        usuario.avatar = newUser.avatar ? import.meta.env.VITE_S3_IMAGEM_URL+newUser.avatar : 'https://www.gravatar.com/avatar/' + email_hash + '?s=200&d=retro';
        usuario.pessoa_fisica_id = newUser.pessoa_fisica_id;
        usuario.nome_pessoa = newUser.nome_pessoa;
    };
    const getToken = () => {
        return token.value
    };
    const setToken = (newToken) => {
        token.value = newToken;
    };
    const getRefreshToken = () => {
        return refreshToken.value
    };
    const setRefreshToken = (newRefreshToken) => {
        refreshToken.value = newRefreshToken;
    };
    const getDecodedToken = () => {
        if (!token.value) {
            return null;
        }
        return jwtDecode(token.value);
    }
    const getUsuarioPermissoes = () => {
        const decodedToken = getDecodedToken();
        if (!decodedToken) {
            return [];
        }
        return decodedToken.permissoes;
    }
    const getUsuarioGrupos = () => {
        const decodedToken = getDecodedToken();
        if (decodedToken == null) {
            return [];
        } else {
            return decodedToken.grupos;
        }
    }
    const getUsuarioProjetos = () => {
        const decodedToken = getDecodedToken();
        if (decodedToken == null) {
            return [];
        } else {
            return decodedToken.projetos;
        }
    }
    
    const logout = () => {
        usuario.id = null;
        usuario.nome = '';  
        usuario.avatar = '';
        usuario.pessoa_fisica_id = null;    
        usuario.nome_pessoa = '';
        usuario.email = '';
        token.value = null;
        refreshToken.value = null;
    };

    return {
        token,
        refreshToken,
        usuario,
        isAuthenticated,
        // validaToken,
        setUsuario,
        getToken,
        getDecodedToken,
        getUsuarioPermissoes,
        getUsuarioGrupos,
        getUsuarioProjetos,
        setToken,
        getRefreshToken,
        setRefreshToken,
        logout,
    }
}, {persist: true});
import { useAuthStore } from "@/stores/auth";
import router from '@/router'

import axios from 'axios';
import { inject } from 'vue';

export const useAxios = () => inject('axios');

export default {
    install: (app, options) => {
        const axiosInstance = axios.create({
            baseURL: options.baseURL,
            headers: {
                'Accept': 'application/json',
            }
        });
        const authStore = useAuthStore();
        axiosInstance.interceptors.request.use(
            function (config) {
                const token = authStore.getToken()
                if (token) {
                    config.headers["Authorization"] = 'Bearer ' + authStore.getToken()
                }
                return config
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        axiosInstance.interceptors.response.use(
            function (response) {
                return response
            },
            function (error) {
                if (error.response.status !== 403) {
                    if (error.response.status === 400) {
                        authStore.logout();
                        router.push({ name: 'login' });
                        return Promise.reject('Unauthorized');
                    }
                    return Promise.reject(error)
                }
                return axios.post('/refresh-token', 
                                    {"refresh_token": authStore.getRefreshToken()},
                                    { 
                                        baseURL: import.meta.env.VITE_API_URL,
                                        headers: {'Accept': 'application/json',}
                                    }
                                ).then((response) => {
                                    if (response.status == 200) {
                                        authStore.setToken(response.data.access_token);
                                        authStore.setRefreshToken(response.data.refresh_token);
                                        return axiosInstance(error.config)
                                    }
                                }).catch(() => {
                                    authStore.logout();
                                    router.push({ name: 'login' });
                                    return Promise.reject(error)
                                })
            }
        );
        app.provide('axios', axiosInstance);
        app.config.globalProperties.$axios = axiosInstance
    }
}
import { createRouter, createWebHistory } from "vue-router"
import { useRouterStore } from '@/stores/routerStore';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/manutencao',
    name: 'manutencao',
    component: () => import('@/views/ManutencaoView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/autocadastro',
    name: 'autoCadastroPessoaFisica',
    component: () => import('../views/pessoa/cadastros/AutoCadastroPessoaFisicaView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/solicitacadastrousuario',
    name: 'solicitaCadastroUsuario',
    component: () => import('../views/pessoa/cadastros/SolicitaAutoCadastroUsuarioView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/cadastrousuario',
    name: 'autoCadastroUsuario',
    component: () => import('../views/pessoa/cadastros/AutoCadastroUsuarioView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/resetsenha',
    name: 'resetSenha',
    component: () => import('../views/pessoa/ResetSenhaView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/esquecisenha',
    name: 'esqueciSenha',
    component: () => import('../views/pessoa/EsqueciSenhaView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/MainView.vue'),
    meta: { requiresAuth: true },
    children: [
      // Home
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        meta: { requiresAuth: true },
      },
      // Dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboards/DashboardView.vue'),
        meta: { requiresAuth: true },
      },
      // Perfil
      {
        path: '/perfil',
        name: 'perfil',
        component: () => import('../views/pessoa/PerfilView.vue'),
        meta: { requiresAuth: true },
      },
      // Meus Projetos
      {
        path: '/meusprojetos',
        name: 'meusProjetos',
        component: () => import('../views/projeto/listas/ListaMeusProjetosView.vue'),
        meta: { requiresAuth: true },
      },
      // Meus Contratos Membro
      {
        path: '/meuscontratos',
        name: 'meusContratos',
        component: () => import('../views/projeto/listas/ListaMeusContratosMembroView.vue'),
        meta: { requiresAuth: true },
      },
      // Detalhe Contrato Membro
      {
        path: '/meuscontratos/:id',
        name: 'detalheContratoMembro',
        component: () => import('../views/projeto/DetalheContratoMembroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Projetos
      {
        path: '/projetos',
        name: 'listaProjetos',
        component: () => import('../views/projeto/listas/ListaProjetosView.vue'),
        meta: { requiresAuth: true },
      },
      // Cadastro de Projetos por Instrumento Jurídico
      {
        path: '/cadastrarprojeto/:ijid',
        name: 'criarProjetoPorInstrumentoJuridico',
        component: () => import('../views/projeto/cadastros/CadastroProjetoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Cadastro de Projeto
      {
        path: '/cadastrarprojeto',
        name: 'cadastrarProjeto',
        component: () => import('../views/projeto/cadastros/CadastroProjetoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      
      // Detalhe de Projeto
      {
        path: '/projeto/:id',
        name: 'detalheProjeto',
        component: () => import('../views/projeto/DetalheProjetoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Detalhe de uma rúbrica
      {
        path:'/projeto/:projeto_id/rubrica/:id',
        name:'detalheRubrica',
        component: () => import('../views/projeto/DetalheRubricaView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Cadastro de Coordenador de Projeto
      {
        path: '/projeto/:id/cadastrocoordenador',
        name: 'cadastroCoordenador',
        component: () => import('../views/projeto/cadastros/CadastroCoordenadorView.vue'),
        meta: { requiresAuth: true },
        props: true
      },

      // Cadastro Solicitacao de Auxilio Financeiro
      {
        path: '/projeto/:id/solicitacaoauciliofinanceiro/',
        name: 'cadastroSolicitacaoAuxilioFinanceiro',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoAuxilioFinanceiroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Auxilio Financeiro
      {
        path: '/solicitacaoauxiliofinanceiro/:idsolicitacao',
        name: 'edicaoSolicitacaoAuxilioFinanceiro',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoAuxilioFinanceiroView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },
      // Cadastro Solicitacao de Passagem
      {
        path: '/projeto/:id/solicitacaopassagem/',
        name: 'cadastroSolicitacaoPassagem',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoPassagemView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Passagem
      {
        path: '/solicitacaopassagem/:idsolicitacao',
        name: 'edicaoSolicitacaoPassagem',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoPassagemView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },
      // Cadastro Solicitacao de Material
      {
        path: '/projeto/:id/solicitacaomaterial/',
        name: 'cadastroSolicitacaoMaterial',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoMaterialView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Material
      {
        path: '/solicitacaomaterial/:idsolicitacao',
        name: 'edicaoSolicitacaoMaterial',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoMaterialView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },
      // Cadastro Solicitacao de Serviço
      {
        path: '/projeto/:id/solicitacaoservico/',
        name: 'cadastroSolicitacaoServico',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoServicoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Serviço
      {
        path: '/solicitacaoservico/:idsolicitacao',
        name: 'edicaoSolicitacaoServico',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoServicoView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },
      // Cadastro Solicitacao de Pagamento de Bolsa
      {
        path: '/projeto/:id/solicitacaopagamentobolsa/',
        name: 'cadastroSolicitacaoPagamentoBolsa',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoPagamentoBolsaView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Pagamento de Bolsa
      {
        path: '/solicitacaopagamentobolsa/:idsolicitacao',
        name: 'edicaoSolicitacaoPagamentoBolsa',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoPagamentoBolsaView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },
      // Cadastro Solicitacao de Hospedagem
      {
        path: '/projeto/:id/solicitacaohospedagem/',
        name: 'cadastroSolicitacaoHospedagem',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoHospedagemView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Hospedagem
      {
        path: '/solicitacaohospedagem/:idsolicitacao',
        name: 'edicaoSolicitacaoHospedagem',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoHospedagemView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },
      // Cadastro Solicitacao de Diaria
      {
        path: '/projeto/:id/solicitacaodiaria/',
        name: 'cadastroSolicitacaoDiaria',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoDiariasView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Diaria
      {
        path: '/solicitacaodiaria/:idsolicitacao',
        name: 'edicaoSolicitacaoDiaria',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoDiariasView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },
      // Cadastro Solicitacao de Pagamento de Serviço
      {
        path: '/projeto/:id/solicitacaopagamentoservico/',
        name: 'cadastroSolicitacaoPagamentoServico',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoPagamentoServicoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição Solicitacao de Pagamento de Serviço
      {
        path: '/solicitacaopagamentoservico/:idsolicitacao',
        name: 'edicaoSolicitacaoPagamentoServico',
        component: () => import('../views/projeto/solicitacao/CadastroSolicitacaoPagamentoServicoView.vue'),
        meta: { requiresAuth: true, isEdicao: true },
        props: true
      },


      // Detalhe de Solicitação
      {
        path: '/solicitacao/:id',
        name: 'detalheSolicitacao',
        component: () => import('../views/projeto/DetalheSolicitacaoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Detalhe de Solicitação Pagamento de Serviço
      {
        path: '/solicitacao/:id/pagamentoservico',
        name: 'detalheSolicitacaoPagamentoServico',
        component: () => import('../views/projeto/DetalheSolicitacaoPagamentoServicoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Detalhe de Solicitação para Compra
      {
        path: '/solicitacaocompras/:id',
        name: 'detalheSolicitacaoCompras',
        component: () => import('../views/contratacao/DetalheSolicitacaoComprasView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Lista de Solicitações para Compra
      {
        path: '/solicitacaocompras',
        name: 'listaSolicitacaoCompras',
        component: () => import('../views/contratacao/ListaSolicitacaoComprasView.vue'),
        meta: { requiresAuth: true },
      },


      // Detalhe de membro
      {
        path: '/membro/:id',
        name: 'detalheMembro',
        component: () => import('../views/projeto/DetalheMembroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Cadastro de Membro
      {
        path: '/projeto/:id/cadastromembro/',
        name: 'cadastroMembro',
        component: () => import('../views/projeto/cadastros/CadastroMembroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição de Membro
      {
        path: '/edicaomembro/:idmembro',
        name: 'edicaoMembro',
        component: () => import('../views/projeto/cadastros/CadastroMembroView.vue'),
        meta: { requiresAuth: true },
        props: true
      },


      // Lista de Pagamentos
      {
        path: '/pagamentos',
        name: 'listaPagamentos',
        component: () => import('../views/projeto/listas/ListaPagamentosView.vue'),
        meta: { requiresAuth: true },
      },
      // Detalhe do pagamento
      {
        path: '/pagamento/:id',
        name: 'detalhePagamento',
        component: () => import('../views/projeto/DetalhePagamentoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Lista de Contratações
      {
        path: '/contratacoes',
        name: 'listaContratacoes',
        component: () => import('../views/contratacao/ListaContratacoesView.vue'),
        meta: { requiresAuth: true },
      },
      // Detalhe de Contratação
      {
        path: '/contratacao/:id',
        name: 'detalheContratacao',
        component: () => import('../views/contratacao/DetalheContratacaoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },


      // Detalhe de Proposta
      {
        path: '/contratacao/:id/cadastroproposta',
        name: 'cadastrarPropostaContratacao',
        component: () => import('../views/contratacao/CadastroContratacaoPropostaView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Cadastro de proposta de contratação
      {
        path: '/contratacaoproposta/:idproposta',
        name: 'edicaoPropostaContratacao',
        component: () => import('../views/contratacao/CadastroContratacaoPropostaView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição de proposta de contratação
      {
        path: '/detalheproposta/:id',
        name: 'detalheproposta',
        component: () => import('../views/contratacao/DetalhePropostaView.vue'),
        meta: { requiresAuth: true },
        props: true
      },


      // Lista de Fornecedores
      {
        path: '/fornecedores',
        name: 'listaFornecedores',
        component: () => import('../views/contratacao/ListaFornecedorView.vue'),
        meta: { requiresAuth: true },
      },
      // Cadastro de Fornecedor
      {
        path: '/fornecedores/cadastro',
        name: 'cadastroFornecedor',
        component: () => import('../views/contratacao/CadastroFornecedorView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edição de Fornecedor
      {
        path: '/fornecedores/editar/:id',
        name: 'edicaoFornecedor',
        component: () => import('../views/contratacao/CadastroFornecedorView.vue'),
        meta: { requiresAuth: true },
        props: true
      },


      // Cadastro de Nota Fiscal
      {
        path: '/contratacao/:id/cadastronotafiscal',
        name: 'cadastroContratacaoNotaFiscal',
        component: () => import('../views/contratacao/CadastroContratacaoNotaFiscalView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Lista de Instrumentos Jurídicos
      {
        path: '/instrumentosjuridicos',
        name: 'listaInstrumentosJuridicos',
        component: () => import('../views/projeto/listas/ListaInstrumentoJuridicoView.vue'),
        meta: { requiresAuth: true },
      },
      // Detalhe de Instrumento Jurídico
      {
        path: '/instrumentojuridico/:id',
        name: 'detalheInstrumentoJuridico',
        component: () => import('../views/projeto/DetalheInstrumentoJuridicoView.vue'),
        meta: { requiresAuth: true },
        props: true
      },


      // Lista de Pessoas Físicas
      {
        path: '/pessoasfisicas',
        name: 'listaPessoaFisica',
        component: () => import('../views/pessoa/listas/ListaPessoaFisicaView.vue'),
        meta: { requiresAuth: true },
      },
      // Cadastro de Pessoa Fisica
      {
        path: '/cadastropessoafisica',
        name: 'cadastroPessoaFisica',
        component: () => import('../views/pessoa/cadastros/CadastroPessoaFisicaView.vue'),
        meta: { requiresAuth: true },
      },
      // Edita Pessoa Fisica
      {
        path: '/edicaopessoafisica/:id',
        name: 'edicaoPessoaFisica',
        component: () => import('../views/pessoa/cadastros/CadastroPessoaFisicaView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Edita Perfil do Usuário
      {
        path: '/perfil/editar',
        name: 'edicaoPerfi',
        component: () => import('../views/pessoa/EdicaoPerfilView.vue'),
        meta: { requiresAuth: true },
        props: true
      },
      // Lista de Pessoas Jurídicas
      {
        path: '/pessoajuridica',
        name: 'listaPessoasJuridicas',
        component: () => import('../views/pessoa/listas/ListaPessoaJuridicaView.vue'),
        meta: { requiresAuth: true },
      },
      // Cadastro de Pessoa Juridica
      {
        path: '/cadastropessoajuridica',
        name: 'cadastroPessoaJuridica',
        component: () => import('../views/pessoa/cadastros/CadastroPessoaJuridicaView.vue'),
        meta: { requiresAuth: true },
      },
      // Edita Pessoa Juridica
      {
        path: '/edicaopessoajuridica/:id',
        name: 'edicaoPessoaJuridica',
        component: () => import('../views/pessoa/cadastros/CadastroPessoaJuridicaView.vue'),
        meta: { requiresAuth: true },
        props: true
      },


      // Lista de Areas de Atuação
      {
        path: '/areasatuacao',
        name: 'listaAreasAtuacao',
        component: () => import('../views/cadastros/ListaAreaAtuacaoView.vue'),
        meta: { requiresAuth: false },
      },
      // Lista de Bancos
      {
        path: '/bancos',
        name: 'listaBancos',
        component: () => import('../views/cadastros/ListaBancoView.vue'),
        meta: { requiresAuth: false },
      },
      // Lista de Escolaridades
      {
        path: '/escolaridades',
        name: 'listaEscolaridades',
        component: () => import('../views/cadastros/ListaEscolaridadeView.vue'),
        meta: { requiresAuth: false },
      },
      // Lista de Tipos de Instrumentos Jurídicos
      {
        path: '/tiposinstrumentosjuridicos',
        name: 'listaTipoInstrumentoJuridico',
        component: () => import('../views/cadastros/ListaTipoInstrumentoJuridicoView.vue'),
        meta: { requiresAuth: false },
      },
      // Lista de Tipo de Status de Projetos
      {
        path: '/statusprojetos',
        name: 'listaTiposStatusProjetos',
        component: () => import('../views/cadastros/ListaTipoStatusProjetoView.vue'),
        meta: { requiresAuth: true },
      },
      // Lista de Projeto Labels
      {
        path: '/projetolabel',
        name: 'listaProjetoLabels',
        component: () => import('../views/cadastros/ListaProjetoLabelView.vue'),
        meta: { requiresAuth: false },
      },
      // Lista de Rubricas Financeiras
      {
        path: '/rubricasfinanceiras',
        name: 'listaRubricasFinanceiras',
        component: () => import('../views/cadastros/ListaRubricaFinanceiraView.vue'),
        meta: { requiresAuth: true },
      },
      // Lista Usuários
      {
        path: '/usuarios',
        name: 'listaUsuarios',
        component: () => import('../views/pessoa/listas/ListaUsuarioView.vue'),
        meta: { requiresAuth: true },
      },
      // Lista de Grupos
      {
        path: '/grupos',
        name: 'listaGrupos',
        component: () => import('../views/pessoa/listas/ListaGrupoView.vue'),
        meta: { requiresAuth: true },
      },
      // Lista de Permissões
      {
        path: '/permissoes',
        name: 'listaPermissoes',
        component: () => import('../views/cadastros/ListaPermissaoView.vue'),
        meta: { requiresAuth: true },
      },
      // Lista de Atividades de Fornecedor
      {
        path: '/atividadesfornecedor',
        name: 'listaAtividadesFornecedor',
        component: () => import('../views/cadastros/ListaAtividadeFornecedorView.vue'),
        meta: { requiresAuth: true },
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


router.beforeEach((to, from, next) => {
  const routerStore = useRouterStore();

  if (from.fullPath != '/') {
    routerStore.definirUltimaRotaVisitada(from);
  }

  if (
    from.fullPath === '/home' ||
    from.fullPath === '/meusprojetos' ||
    from.fullPath === '/instrumentosjuridicos' ||
    from.fullPath === '/projetos' ||
    from.fullPath === '/meuscontratos' ||
    from.fullPath === '/solicitacaocompras' ||
    from.fullPath === '/contratacoes'
  ) {
    routerStore.definirRotaOrigem(from);
  }

  window.scrollTo(0, 0);
  next();
});


export default router
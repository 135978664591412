// Objetivo: Funções utilitárias que podem ser utilizadas em qualquer lugar do projeto
import { useAuthStore } from "@/stores/auth";

export function getUfs() {
    return [
        { sigla: 'AC', nome: 'Acre' },
        { sigla: 'AL', nome: 'Alagoas' },
        { sigla: 'AP', nome: 'Amapá' },
        { sigla: 'AM', nome: 'Amazonas' },
        { sigla: 'BA', nome: 'Bahia' },
        { sigla: 'CE', nome: 'Ceará' },
        { sigla: 'DF', nome: 'Distrito Federal' },
        { sigla: 'ES', nome: 'Espírito Santo' },
        { sigla: 'GO', nome: 'Goiás' },
        { sigla: 'MA', nome: 'Maranhão' },
        { sigla: 'MT', nome: 'Mato Grosso' },
        { sigla: 'MS', nome: 'Mato Grosso do Sul' },
        { sigla: 'MG', nome: 'Minas Gerais' },
        { sigla: 'PA', nome: 'Pará' },
        { sigla: 'PB', nome: 'Paraiba' },
        { sigla: 'PR', nome: 'Paraná' },
        { sigla: 'PE', nome: 'Pernambuco' },
        { sigla: 'PI', nome: 'Piauí' },
        { sigla: 'RJ', nome: 'Rio de Janeiro' },
        { sigla: 'RN', nome: 'Rio Grande do Norte' },
        { sigla: 'RS', nome: 'Rio Grande do Sul' },
        { sigla: 'RO', nome: 'Rondônia' },
        { sigla: 'RR', nome: 'Roraima' },
        { sigla: 'SC', nome: 'Santa Catarina' },
        { sigla: 'SP', nome: 'São Paulo' },
        { sigla: 'SE', nome: 'Sergipe' },
        { sigla: 'TO', nome: 'Tocantins' },
    ]
}

export function showLogConsole(dado) {
    if (import.meta.env.VITE_APP_DEBUG == "true") {
        console.log(dado);
    }
}





export function permutaSolicitacaoContratacao(nome) {
    if (nome) {
        return nome.replace("Solicitação", "Contratação");
    }
    return nome
}

export function generatePassword() {
    // return Math.random().toString(36).slice(-8);
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 8;
    var password = "";
    for (var i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password
}

export function buscarItemPorId(lista, id) {
    var index = -1;
    for (var i = 0; i < lista.length; i++) {
        if (lista[i].id === id) {
            index = i;
            break;
        }
    }
    return index;
}

export function buscarItemPorNome(lista, nome) {
    var index = -1
    for (var i = 0; i < lista.length; i++) {
        if (lista[i].nome === nome) {
            index = i;
            return i;
        }
    }
    return index;
};

export function ehVazio(valor) {
    return valor === null || valor === undefined || valor === '';
};

export function validarPermissao(permissao) {
    const authStore = useAuthStore();

    const usuarioPermissoes = authStore.getUsuarioPermissoes();

    if (usuarioPermissoes.includes(permissao) || usuarioPermissoes.includes('all:all')) {
        return true;
    } else {
        return false;
    }
}

export function formataDataFromDb(datastr) {
    const data = new Date(datastr + 'T00:00:00')
    return data.toLocaleDateString('pt-BR')
}

export function formataDataHoraFromDb(datastr) {
    const data = new Date(datastr)
    return data.toLocaleString('pt-BR')
}

export function mudaFormatoDataSep(datastr) {
    if (datastr) {
        if (datastr.search('/') >= 0) {
            let novaData = datastr.split('/')
            novaData = novaData[2] + '-' + novaData[1] + '-' + novaData[0] // Formatar data para YYYY-MM-DD
            return novaData
        } else if (datastr.search('-') >= 0) {
            let novaData = datastr.split('-')
            novaData = novaData[2] + '/' + novaData[1] + '/' + novaData[0] // Formatar data para DD/MM/YYYY
            return novaData
        }
    }
}
export function mudaFormatoMesAno(datastr) {
    if (datastr) {
        if (datastr.search('/') >= 0) {
            let novaData = datastr.split('/')
            novaData = novaData[1] + '-' + novaData[0] // Formatar data para MM/YYYY
            return novaData
        } else if (datastr.search('-') >= 0) {
            let novaData = datastr.split('-')
            novaData = novaData[1] + '/' + novaData[0] // Formatar data para MM/YYYY
            return novaData
        }
    }
}
export function formataMoedaReais(valor) {
    const reais = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
    return reais.format(valor)
}

export function formataCpf(cpf, mascarar = true) {
    if (cpf) {
        if (mascarar) {
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**")
        } else {
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        }
    }
}

export function formataCnpj(cnpj, mascarar = false) {
    if (cnpj) {
        if (mascarar) {
            return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "**.$2.$3/$4-**")
        } else {
            return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        }
    }
}

export function formataNis(nis) {
    if (nis) {
        return nis.replace(/(\d{3})(\d{5})(\d{2})(\d{1})/, "$1.$2.$3-$4")
    }
}

export function formataCep(cep) {
    if (cep) {
        return cep.replace(/(\d{5})(\d{3})/, "$1-$2")
    }
}

export function formataNome(nome) {
    if (nome) {
        const partes = nome.split(" ");
        if (partes.length >= 2) {
            let primeiroNome = partes[0];
            let segundoNome = partes[1];
            let terceiroNome = partes[2];

            // Verifica se a segunda palavra é uma preposição
            const preposicoes = ["dos", "das", "do", "da", "em", "e"];

            if (preposicoes.includes(segundoNome) || segundoNome.length === 1) {
                return [primeiroNome, segundoNome, terceiroNome].join(" ");
            } else {
                return [primeiroNome, segundoNome].join(" ");
            }
        }
        else {
            return partes[0];
        }
    }
}

export function formataTelefone(telefone) {
    if (telefone) {
        if (telefone.length == 10) {
            return telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
        }
        else if (telefone.length == 11) {
            return telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
        }

    }
}

export function removeMascaraTelefone(telefone) {
    if (telefone) {
        return telefone.replace(/[()\s-]/g, '')
    }
}

export function isValidDate(dateString) {
    const [dia, mes, ano] = dateString.split('/').map(Number);
    const data = new Date(`${ano}-${mes}-${dia}`);
    if (isNaN(data.getTime())) return false;

    const isLeapYear = (ano % 4 === 0 && ano % 100 !== 0) || (ano % 400 === 0);
    if (mes === 2 && dia > 29) return false;
    if (mes === 2 && dia === 29 && !isLeapYear) return false;
    const diasPorMes = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return dia <= diasPorMes[mes - 1];
};

export function isValidDateMesAno(dateString) {
    const [mes, ano] = dateString.split('/').map(Number);
    const data = new Date(`${ano}-${mes}`);
    if (isNaN(data.getTime())) return false;

    return true;
}

export function locale_pt_br() {
    return {
        "startsWith": "Começa com",
        "contains": "Contém",
        "notContains": "Não contém",
        "endsWith": "Termina com",
        "equals": "Igual",
        "notEquals": "Diferente",
        "noFilter": "Sem Filtro",
        "filter": "Filtro",
        "lt": "Menor que",
        "lte": "Menor ou igual a",
        "gt": "Maior que",
        "gte": "Maior ou igual a",
        "dateIs": "Data é",
        "dateIsNot": "Data não é",
        "dateBefore": "Data antes de",
        "dateAfter": "Data depois de",
        "custom": "Personalizado",
        "clear": "Limpar",
        "apply": "Aplicar",
        "matchAll": "Corresponder Todos",
        "matchAny": "Corresponder Qualquer",
        "addRule": "Adicionar Regra",
        "removeRule": "Remover Regra",
        "accept": "Sim",
        "reject": "Não",
        "choose": "Escolher",
        "upload": "Enviar",
        "cancel": "Cancelar",
        "completed": "Concluído",
        "pending": "Pendente",
        "fileSizeTypes": ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        "dayNames": ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
        "dayNamesShort": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        "dayNamesMin": ["D", "S", "T", "Q", "Q", "S", "S"],
        "monthNames": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        "chooseYear": "Escolher Ano",
        "chooseMonth": "Escolher Mês",
        "chooseDate": "Escolher Data",
        "prevDecade": "Década Anterior",
        "nextDecade": "Próxima Década",
        "prevYear": "Ano Anterior",
        "nextYear": "Próximo Ano",
        "prevMonth": "Mês Anterior",
        "nextMonth": "Próximo Mês",
        "prevHour": "Hora Anterior",
        "nextHour": "Próxima Hora",
        "prevMinute": "Minuto Anterior",
        "nextMinute": "Próximo Minuto",
        "prevSecond": "Segundo Anterior",
        "nextSecond": "Próximo Segundo",
        "am": "AM",
        "pm": "PM",
        "today": "Hoje",
        "now": "Agora",
        "weekHeader": "Sem",
        "firstDayOfWeek": 0,
        "showMonthAfterYear": false,
        "dateFormat": "dd/MM/yy",
        "weak": "Fraco",
        "medium": "Médio",
        "strong": "Forte",
        "passwordPrompt": "Digite uma senha",
        "emptyFilterMessage": "Nenhum resultado encontrado",
        "searchMessage": "Existem {0} resultados disponíveis",
        "selectionMessage": "{0} itens selecionados",
        "emptySelectionMessage": "Nenhum item selecionado",
        "emptySearchMessage": "Nenhum resultado encontrado",
        "emptyMessage": "Nenhuma opção disponível",
        "aria": {
            "trueLabel": "Verdadeiro",
            "falseLabel": "Falso",
            "nullLabel": "Não Selecionado",
            "star": "1 estrela",
            "stars": "{star} estrelas",
            "selectAll": "Todos os itens selecionados",
            "unselectAll": "Todos os itens desmarcados",
            "close": "Fechar",
            "previous": "Anterior",
            "next": "Próximo",
            "navigation": "Navegação",
            "scrollTop": "Rolar para o Topo",
            "moveTop": "Mover para o Topo",
            "moveUp": "Mover para Cima",
            "moveDown": "Mover para Baixo",
            "moveBottom": "Mover para o Final",
            "moveToTarget": "Mover para o Destino",
            "moveToSource": "Mover para a Origem",
            "moveAllToTarget": "Mover Todos para o Destino",
            "moveAllToSource": "Mover Todos para a Origem",
            "pageLabel": "Página {page}",
            "firstPageLabel": "Primeira Página",
            "lastPageLabel": "Última Página",
            "nextPageLabel": "Próxima Página",
            "previousPageLabel": "Página Anterior",
            "rowsPerPageLabel": "Linhas por página",
            "jumpToPageDropdownLabel": "Ir para a Página",
            "jumpToPageInputLabel": "Ir para a Página",
            "selectRow": "Linha Selecionada",
            "unselectRow": "Linha Desmarcada",
            "expandRow": "Expandir Linha",
            "collapseRow": "Recolher Linha",
            "showFilterMenu": "Mostrar Menu de Filtro",
            "hideFilterMenu": "Esconder Menu de Filtro",
            "filterOperator": "Operador de Filtro",
            "filterConstraint": "Restrição de Filtro",
            "editRow": "Editar Linha",
            "saveEdit": "Salvar Edição",
            "cancelEdit": "Cancelar Edição",
            "listView": "Visualização de Lista",
            "gridView": "Visualização de Grade",
            "slide": "Deslizar",
            "slideNumber": "Slide {slideNumber}",
            "zoomImage": "Ampliar Imagem",
            "zoomIn": "Ampliar",
            "zoomOut": "Reduzir",
            "rotateRight": "Rotacionar para a Direita",
            "rotateLeft": "Rotacionar para a Esquerda"
        }
    }
}

export function formataTamanhoArquivo(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function openPDF(file) {
    if (!(file instanceof Blob)) {
        showLogConsole('O objeto passado não é um Blob:', file);
        return;
    }

    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
    URL.revokeObjectURL(fileURL);
};



export const grupoUsuario_analistaprojeto = import.meta.env.VITE_GRUPO_USUARIO_ANALISTAPROJETO;
export const grupoUsuario_administrador = import.meta.env.VITE_GRUPO_USUARIO_ADMINISTRADOR;
export const grupoUsuario_analistacrci = import.meta.env.VITE_GRUPO_USUARIO_ANALISTACRCI;
export const grupoUsuario_analistafinanceiro = import.meta.env.VITE_GRUPO_USUARIO_ANALISTAFINANCEIRO;
export const grupoUsuario_analistacompra = import.meta.env.VITE_GRUPO_USUARIO_ANALISTACOMPRAS;
export const grupoUsuario_ordenadordespesa = import.meta.env.VITE_GRUPO_USUARIO_ORDENADORDESPESA;
export const grupoUsuario_membro = import.meta.env.VITE_GRUPO_USUARIO_MEMBRO;
export const grupoUsuario_coordenador = import.meta.env.VITE_GRUPO_USUARIO_COORDENADOR;




